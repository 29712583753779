import React, { useEffect, useState } from 'react';
import { Routes,Route,useNavigate,Link } from 'react-router-dom';
import Deposit from './pages/Deposit';
import Botfee from './pages/Botfee';
import Package from './pages/Package';
import Transfer from './pages/Transfer';
import Withdrawal from './pages/Withdrawal';

import Validatewallet from './pages/Validatewallet';


function App() {
  return (
    <div>


<Routes>
    <Route path='/deposit/:userid' element={<Deposit />} />
    <Route path='/botfee/:userid' element={<Botfee />} />
    <Route path='/package/:userid' element={<Package />} />
    <Route path='/transfer/:userid' element={<Transfer />} />
    <Route path='/withdrawal/:userid' element={<Withdrawal />} />
    <Route path='/validatewallet/:userid' element={<Validatewallet />} />
</Routes>


      
    </div>
  );
}

export default App;
