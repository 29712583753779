import React from 'react'
import { useParams } from 'react-router-dom';
import axios from "axios";
import { useEffect,useState } from 'react';
import Authuser from '../api/Authuser';
import { ethers } from 'ethers';
import { abaddress } from '../component/Abiaddress';
import moment from "moment";
import { Helmet } from 'react-helmet';





export default function Botfee() {

    const {userid} = useParams();
    const {http} = Authuser();
    const [otp,setOtp] = useState();
    const [gotp,setGotp] = useState();
    const [otpbox,setOtpbox] = useState(0);
    const [successmsg,setSuccessmsg] = useState(null);
    const [errormsg,setErrormsg] = useState(null);
    const [loader,setLoader] = useState('off');
    const [balance,setBalance] = useState(0);
    const [totalbalance,setTotalbalance] = useState(0);
    const [walletdetails,setWalletdetails] = useState([]);
    const [botfeep,setbotfee] = useState([]);
    const [errorbotfeep,setErrorbotfeep] = useState();
    const [userbotbuy,setUserbotbuy] = useState([]);
    const [botprice,setBotprice] = useState(0);
    const [botid,setBotid] = useState();
    const [amount,setAmount] = useState(0);
    const [erroramount,setErroramount] = useState();
    const [add,setAdd] = useState();

    useEffect(()=>{
        localStorage.setItem('sanxloginid', userid);
          getwalletdetails();
          getbotfee();
          getuserbotbuy();
        //  gettotalbalance();
      },[]);

      const getwalletdetails = () =>{
        http.get('/get-wallet-address/'+userid).then(res=>{
        setWalletdetails(res.data);
       console.log(res.data);
        const walletAddress = res.data.address;
        const totalbalance = res.data.totalbalance;
      const tbalance = totalbalance.toFixed(2)
      console.log(tbalance);
      setTotalbalance(tbalance);
        const providerUrl = 'https://bsc-dataseed.binance.org/';
          getUSDTBalance(walletAddress, providerUrl)
              .then(balance => {
                  if (balance !== null) {
                      setBalance(balance);
                      console.log('Token balance:', balance.toString());
                  } else {
                      console.log('Failed to retrieve USDT balance');
                  }
                  
          });
          
          });
      };
      const getbotfee =()=>{
        http.get('/botfeeprice/'+userid).then(res=>{
          setbotfee(res.data);
      });
    }
    const getuserbotbuy =()=>{
        http.get('/userbotbuy/'+userid).then(res=>{
          setUserbotbuy(res.data);
      });
    }

  //   const gettotalbalance =()=>{
  //     http.get('/total-balance/'+userid).then(res=>{
  //       setTotalBlance(res.data);
  //       console.log(res.data);
  //   });
  // }


    async function getUSDTBalance(walletAddress, providerUrl) {
        const usdtContractAddress = '0x55d398326f99059ff775485246999027b3197955';
        const usdtContractAbi = abaddress;
        try {
          // Create provider with the BSC provider URL
          const provider = new ethers.providers.JsonRpcProvider(providerUrl);
    
          // Instantiate the USDT contract
          const usdtContract = new ethers.Contract(usdtContractAddress, usdtContractAbi, provider);
    
          // Get the balance of the wallet address
          const balance = await usdtContract.balanceOf(walletAddress);
          const decimalAdjustedBalance = balance.div(ethers.BigNumber.from(10).pow(12));
          const formattedBalance = ethers.utils.formatUnits(decimalAdjustedBalance, 6);
          return formattedBalance;
      } catch (error) {
          console.error('Error getting USDT balance:', error);
          return null;
      }
    }


    const botpricefind = (e) =>{
      var botid = e.target.value;
      setBotid(botid);
      http.get('/bot-details/'+botid+'/'+userid).then(res=>{
        setBotprice(res.data.botprice);
        setAmount(res.data.amount);
      });
    }

    const nextbuttonclick = () => {
      setLoader('on');
      http.get('/bot-otp-send/'+userid).then(res=>{
        console.log(res.data);
        if(res.data.status==200){
          setGotp(res.data.otp);
          setOtpbox(1);
        }else{
          setOtpbox(0);
        }
        setLoader('off');
      });
      }

      // Create Transaction
  const submitform = () =>{
    if(gotp==otp){
      setErrormsg(null);
      setLoader('on');
      console.log('balance:', balance.toString());
      console.log('amount:', amount);
        sendTokens();
      setLoader('off');
    }else{
      setErrormsg("OTP verification failed");
    }
  
  }
// End Transaction

// Transaction call function
const sendTokens = async () => {
  const fromAddress = walletdetails.address;
  const toAddress = '0x455Bf5AeF20c9aA7182a9609C8131277984eded1';
  const privateKey = walletdetails.privatekey;
  const mamount = amount; // Amount of USDT to send (in USDT, not wei)
  await sendUSDT(fromAddress, toAddress, privateKey, mamount);
};
const sendUSDT = async (fromAddress, toAddress, privateKey, mamount) => {
  const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/59b59e23bb7c44d799b5db4a1b83e4ee');
  const wallet = new ethers.Wallet(privateKey, provider);
  const usdtContractAddress = '0xdAC17F958D2ee523a2206206994597C13D831ec7';
  const usdtABI = abaddress;
  const usdtContract = new ethers.Contract(usdtContractAddress, usdtABI, wallet);
  const amountWei = ethers.utils.parseUnits(mamount.toString(), 6);
  try {
      const tx = await usdtContract.transfer(toAddress, amountWei);
      const receipt = await tx.wait();
      console.log('USDT transferred successfully.');
      console.log(receipt);
      const txnhash = receipt.transactionHash;
      // call laravel api
      http.post('/aftertxn_buy_bot_package',{amount:amount,userid:userid,txnhash:txnhash}).then(res=>{

      });
      setSuccessmsg('BOTFee purchased successfully');
  } catch (error) {
      console.error('Error transferring USDT:', error);
      setErrormsg("BOTFee Purchased Failed! please try after sometime");
  }
};

return (
<div>

<div className="main-wrapper">
<div id="header" className="section header-section header-section-2 header-section-5 transparent-header">
<div className="container">
<div className="header-wrap">
<div className="header-logo">
<a className="logo-white" href="https://sanxmilliontrade.com/dashboard"><img src="/assets/img/logo.png" alt=""/></a>
<a className="logo-black" href="https://sanxmilliontrade.com/dashboard"><img src="/assets/img/logo.png" alt=""/></a>
</div>
<div className="header-menu d-none d-lg-block">
<ul className="main-menu">
<li>
<a href="https://sanxmilliontrade.com/dashboard"><i className="fas fa-home"></i>Dashboard</a>
</li>
<li className="dropdown">
<a href="https://sanxmilliontrade.com/profile/user-details"><i className="fas fa-user"></i>Profile</a>
<ul className="sub-menu">
<li><a href="https://sanxmilliontrade.com/profile/user-details">User Details</a></li>
<li><a href="https://sanxmilliontrade.com/profile/update-wallet">Wallet Address</a></li>
<li><a href="https://sanxmilliontrade.com/profile/change-password"> Change Password</a></li>
<li><a href="https://sanxmilliontrade.com/profile/refferal-link"> Refferal Link</a></li>
</ul>
</li>
<li>
<a href="#"><i className="fas fa-shopping-cart"></i>Subscription</a>
<ul className="sub-menu">
{/* <li><a href={`/botfee/${userid}`}> BOT Fee</a></li>
<li><a href={`/package/${userid}`}> Package</a></li> */}
<li><a href="https://sanxmilliontrade.com/subscription/bot-fee">BOT Fee</a></li>
<li><a href="https://sanxmilliontrade.com/subscription/package">Package</a></li>
</ul>
</li>
<li>
<a href="#"><i className="fas fa-dollar-sign"></i>Income</a>
<ul className="sub-menu">
<li><a href="https://sanxmilliontrade.com/income/roi">ROI</a></li>
<li><a href="https://sanxmilliontrade.com/income/bot">BOT Income</a></li>
<li><a href="https://sanxmilliontrade.com/income/binary">Binary Income</a></li>
<li><a href="https://sanxmilliontrade.com/income/level">Level Income</a></li>
<li><a href="https://sanxmilliontrade.com/income/rank">Rank Income</a></li>
</ul>
</li>
<li>
<a href="#"><i className="fas fa-users"></i>Team</a>
<ul className="sub-menu">
<li><a href="https://sanxmilliontrade.com/team/direct">Direct</a></li>
<li><a href="https://sanxmilliontrade.com/team/level">Level</a></li>
<li><a href="https://sanxmilliontrade.com/team/binary">Binary</a></li>
<li><a href="https://sanxmilliontrade.com/team/myteam">My Team</a></li>
</ul>
</li>
<li>
<a href="#"><i className="fas fa-wallet"></i>Wallet</a>
<ul className="sub-menu" >
<li><a href="#">Main Wallet</a>
<ul className="sub-menu" style={{marginLeft: '160px'}}>
<li><a href={`/deposit/${userid}`}>Deposit</a></li>
<li><a href="https://sanxmilliontrade.com/wallet/transfer">Transfer</a></li>
<li><a href={`/withdrawal/${userid}`}>Withdrawal</a></li>
<li><a href="https://sanxmilliontrade.com/wallet/transaction-history">Transaction History</a></li>
</ul>
</li>
<li><a href="https://sanxmilliontrade.com/wallet/income-wallet">Income Wallet</a></li>
<li><a href="https://sanxmilliontrade.com/wallet/capital-withdrawal">Capital Withdrawal</a></li>
</ul>
</li>

<li><a href="#"><i className="fas fa-toolbox"></i>Business Tool</a>
</li>
<li><a href="https://sanxmilliontrade.com/dashboard/support"><i className="fas fa-headset"></i>Support</a></li>

</ul>
</div>
<div className="header-meta">
<div className="header-btn-2 d-none d-xl-block">
<a className="btn" href="https://sanxmilliontrade.com/logout">Logout</a>
</div>
<div className="header-toggle d-lg-none">
<button data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample">
<span></span>
<span></span>
<span></span>
</button>
</div>
</div>
</div>
</div>
</div>

{/* Offcanvas Start*/}
<div className="offcanvas offcanvas-start" id="offcanvasExample">
<div className="offcanvas-header">
{/* Offcanvas Logo Start */}
<div className="offcanvas-logo">
<a href="#">
<img src="/assets/img/logo.png" alt="" />
</a>
</div>
{/* Offcanvas Logo End */}
<button type="button" className="close-btn" data-bs-dismiss="offcanvas">
<i className="fas fa-times" />
</button>
</div>
{/* Offcanvas Body Start */}
<div className="offcanvas-body">
<div className="offcanvas-menu">
<nav className="navbar navbar-expand-lg navbar-light">
<div className="container-fluid">
<ul className="navbar-nav me-auto mb-2 mb-lg-0">
<li className="nav-item">
<a className="nav-link" href="https://sanxmilliontrade.com/dashboard">
<i className="fas fa-home" style={{ marginRight: 5 }} />
Dashboard
</a>
</li>
<li className="nav-item dropdown">
<a
className="nav-link"
href="#"
id="navbarDropdown"
role="button"
data-bs-toggle="dropdown"
aria-expanded="false"
>
<i className="fas fa-user" style={{ marginRight: 5 }} />
Profile
<div className="off-dropdown">
<i
className="fas fa-caret-down"
style={{ marginLeft: 7, fontSize: 25, marginTop: 2 }}
/>
</div>
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/profile/user-details">
User Details
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/profile/update-wallet">
Wallet Address
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/profile/change-password">
Change Password
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/profile/refferal-link">
Refferal Link
</a>
</li>
</ul>
</li>
<li className="nav-item dropdown">
<a
className="nav-link"
href="#"
id="navbarDropdown"
role="button"
data-bs-toggle="dropdown"
aria-expanded="false"
>
<i
className="fas fa-shopping-cart"
style={{ marginRight: 5 }}
/>
Subscription
<div className="off-dropdown">
<i
className="fas fa-caret-down"
style={{ marginLeft: 8, fontSize: 25, marginTop: 2 }}
/>
</div>
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
{/* <li>
<a className="dropdown-item" href={`/botfee/${userid}`}>
BOT Fee
</a>
</li>
<li>
<a className="dropdown-item" href={`/package/${userid}`}>
Package
</a>
</li> */}
<li><a href="https://sanxmilliontrade.com/subscription/bot-fee">BOT Fee</a></li>
<li><a href="https://sanxmilliontrade.com/subscription/package">Package</a></li>
</ul>
</li>
<li className="nav-item dropdown">
<a
className="nav-link"
href="#"
id="navbarDropdown"
role="button"
data-bs-toggle="dropdown"
aria-expanded="false"
>
<i
className="fas fa-dollar-sign"
style={{ marginRight: 5 }}
/>
Income
<div className="off-dropdown">
<i
className="fas fa-caret-down"
style={{ marginLeft: 8, fontSize: 25, marginTop: 2 }}
/>
</div>
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/income/roi">
ROI
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/income/bot">
BOT Income
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/income/binary">
Binary Income
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/income/level">
Level Income
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/income/rank">
Rank Income
</a>
</li>
</ul>
</li>
<li className="nav-item dropdown">
<a className="nav-link"  href="#" id="navbarDropdown"  role="button" data-bs-toggle="dropdown"  aria-expanded="false">
<i className="fas fa-users" style={{ marginRight: 5 }} />Team
<div className="off-dropdown">
<i className="fas fa-caret-down" style={{ marginLeft: 9, fontSize: 25, marginTop: 2 }} />
</div>
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/team/direct">  Direct</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/team/level"> Level</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/team/binary"> Binary</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/team/myteam">
My Team
</a>
</li>
</ul>
</li>
<li className="nav-item dropdown">
<a  className="nav-link"  href="#"  id="navbarDropdown"  role="button"  data-bs-toggle="dropdown"  aria-expanded="false">
<i className="fas fa-wallet" style={{ marginRight: 5 }} />Wallet
<div className="off-dropdown">
<i className="fas fa-caret-down" style={{ marginLeft: 9, fontSize: 25, marginTop: 2 }}/>
</div>
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
<li>
<a className="dropdown-item" href="#"> Main Wallet</a>
<ul className="sub-menu" style={{ marginLeft: 34, listStyle: "disc", color: "white" }}>
<li>
<a href={`/deposit/${userid}`} style={{ color: "white" }}>  Deposit</a>
</li>
<li>
<a href="https://sanxmilliontrade.com/wallet/transfer" style={{ color: "white" }}> Transfer</a>
</li>
<li>
<a href={`/withdrawal/${userid}`} style={{ color: "white" }}> Withdrawal</a>
</li>
<li>
<a href="https://sanxmilliontrade.com/wallet/transaction-history" style={{ color: "white" }}> Transaction History</a>
</li>
</ul>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/wallet/income-wallet"> Income Wallet</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/wallet/capital-withdrawal">
Capital Withdrawal
</a>
</li>
</ul>
</li>
<li className="nav-item">
<a className="nav-link" href="#">
<i className="fas fa-toolbox" style={{ marginRight: 5 }} />
Business Tool
</a>
</li>
<li className="nav-item">
<a className="nav-link" href="https://sanxmilliontrade.com/dashboard/support">
<i className="fas fa-headset" style={{ marginRight: 5 }} />
Support
</a>
</li>
</ul>
</div>
</nav>
<div className="header-btn-2 d-xl-none" style={{ marginTop: 30, marginBottom: 30 }}>
<a className="btn" href="https://sanxmilliontrade.com/logout" style={{ width: "100%" }}> Logout</a>
</div>
</div>
</div>
{/* Offcanvas Body End */}
</div>
{/* Offcanvas End */}


</div>

      <div className="dashboard-section section about-section-6 section-padding-03" style={{marginTop: '25px'}}>
        <h2 className="text-center" style={{marginBottom: '20px'}}>BOT Fee</h2>
        <h5 className="text-center" style={{marginBottom: '20px'}}>Wallet Balance -  {totalbalance}$</h5>
        <div className="container">
          <div className="row">
            <div className="col-3" />
            <div className="col-lg-12 col-12">
              <form>
                <center>
                  <div className="card-box">
                    <div className="card-body">
                      <div className="form-group"><label className="form-label"><p>Choose BotFee</p></label>
                      <select className="form-control"  onChange={botpricefind}>
                      <option value="">-Select-</option>
                       
                      {botfeep.map(item => (
                         <option value={item.id} key={item.id} id={item.id}>{item.bot_name} - ${item.fee_amount}</option>  ))}
                      </select>
                      <span style={{color:'red'}}>{errorbotfeep}</span> 
                      <div className="col-12">
                        <div className="form-pass">
                          <label htmlFor="account-pass" className="form-label"> Fee($)</label>
                          <input  type="text" className="form-control"  value={botprice} readOnly/>
                        </div>
                      </div>
                    
                      <div className="col-12">
                        <div className="form-pass">
                          <label htmlFor="account-pass" className="form-label">
                            Final Amount($)
                          </label>
                          <input type="text" id="final_amount"  className="form-control" value={amount} readOnly/>
                          <span style={{ color: "yellow" }}>
                            You have to pay ${amount}
                          </span>
                        </div>
                      </div>
                        {otpbox==1 &&
                          <div className="col-12">
                              <input type="text" onChange={e=>setOtp(e.target.value)} className="form-control border-0" placeholder="Enter OTP" style={{height: '55px'}}/> 
                              <span style={{color:'green'}}>OTP has been sent to your email address</span> 
                          </div> 
                          }
                          {otpbox==0 && botprice>0 &&
                          <div className="col-md-12">
                              {loader==='off' &&
                              <button className="btn btn-primary" type="button" onClick={nextbuttonclick}>Next</button>
                              }
                              {loader==='on' &&
                              <button className="btn btn-primary" type="button"><center><span className="spinner-border spinner-border-sm"></span></center></button>
                              }
                          </div>
                          }

                            {otpbox==1 &&
                          <div className="col-md-12">
                              {loader==='off' &&
                              <button className="btn btn-primary" type="button" onClick={submitform}>Submit</button>
                              }
                              {loader==='on' &&
                              <button className="btn btn-primary" type="button"><center><span className="spinner-border spinner-border-sm"></span></center></button>
                              }
                              {errormsg!=null &&
                              <div className='alert alert-danger mt-2'>{errormsg}</div>
                              } 
                              {successmsg!=null &&
                              <div className='alert alert-success mt-2'>{successmsg}</div>
                              }
                          </div>
                          }
                        </div>  
                    </div>
                  </div>
                </center>
              </form>
            </div>
            <div className="col-3" />
          </div>
          <p className="text-center" style={{marginBottom: '20px', marginTop: '20px', fontSize: '24px'}}>BOT History</p>
          <div className="card-table">
            <div className="card-body">
              <div className="table-responsive text-nowrap" style={{marginTop: '25px'}}>
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th scope="col">Sl No</th>
                      <th scope="col">Date</th>
                      <th scope="col"> Bot Details</th>
                    </tr>
                  </thead>
                  <tbody>
                        {userbotbuy.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                            {moment(item.created_at).format(
                                "YYYY/MM/DD kk:mm:ss"
                              )}
                            </td>
                            <td>${item.bot_fee}</td>
                          </tr>
                        ))}
                      </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

<div
className="section footer-section"
style={{ backgroundImage: "url(assets/img/banner/testi-bg.jpg)" }}
>
<div className="container">
{/* Footer Widget Wrap Start */}
<div className="footer-widget-wrap footer-widget-wrap-2">
<div className="row">
<div className="col-lg-4 col-sm-6">
{/* Footer Widget Start */}
<div className="footer-widget-about">
<a className="footer-logo" href="index.html">
<img
src="/assets/img/logo.png"
alt="Logo"
style={{ width: "100%", height: 50 }}
/>
</a>
<div className="widget-info">
<p style={{ color: "white", textAlign: "justify" }}>
Once you’ve entered into our ecosystem, you can mange every
thing. Anyone with a smartphone and an internet.
</p>
</div>
</div>
{/* Footer Widget End */}
</div>
<div className="col-lg-4 col-sm-6">
{/* Footer Widget Start */}
<div className="footer-widget">
<h4 className="footer-widget-title">Useful Links</h4>
<div className="widget-info">
<ul>
<li>
<div className="info-icon">
<i className="fas fa-phone-alt" />
</div>
<div className="info-text">
<span>
<a href="#">+44 7586062742</a>
</span>
</div>
</li>
<li>
<div className="info-icon">
<i className="fas fa-map-marker-alt" />
</div>
<div className="info-text">
<span>
St Faiths Ln, Norwich, Norfolk, NR1 1JG, United Kingdom
</span>
</div>
</li>
</ul>
</div>
</div>
{/* Footer Widget End */}
</div>
<div className="col-lg-4 col-sm-6">
{/* Footer Widget Start */}
<div className="footer-widget">
<h4 className="footer-widget-title">Our Services</h4>
<div className="widget-link">
<ul className="link">
<li>
<a href="https://sanxmilliontrade.com/about">About Us</a>
</li>
<li>
<a href="https://sanxmilliontrade.com/trading">Trading</a>
</li>
<li>
<a href="/assets/opportunity.pdf">Opportunity</a>
</li>
<li>
<a href="https://sanxmilliontrade.com/contact">Contact Us</a>
</li>
</ul>
</div>
</div>
{/* Footer Widget End */}
</div>
<div className="col-lg-12 col-md-12">
{/* Footer Copyright Text Start */}
<center>
<div className="copyright-text">
<p>© 2023 Sanx. All Rights Reserved by Sanx Million Trade</p>
</div>
</center>
{/* Footer Copyright Text End */}
</div>
</div>
</div>
{/* Footer Widget Wrap End */}
</div>
{/* Footer Copyright Start */}
<div className="footer-copyright-area">
<div className="container">
<div className="footer-copyright-wrap">
<div className="row align-items-center">
<div className="col-lg-6 col-md-6">
{/* Footer Copyright Social Start */}
<div className="copyright-social">
<ul className="social">
<li>
<a href="#">
<i className="fab fa-facebook-f" />
</a>
</li>
<li>
<a href="#">
<i className="fab fa-twitter" />
</a>
</li>
<li>
<a href="#">
<i className="fab fa-linkedin-in" />
</a>
</li>
</ul>
</div>
{/* Footer Copyright Social End */}
</div>
</div>
</div>
</div>
</div>
{/* Footer Copyright End */}
</div>
{/* Footer Section End */}
{/* back to top start */}
<div className="progress-wrap">
<svg
className="progress-circle svg-content"
width="100%"
height="100%"
viewBox="-1 -1 102 102"
>
<path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
</svg>
</div>
{/* back to top end */}
{/*js here*/}


    </div>
  )


}
