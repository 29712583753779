import React, { useEffect, useState,useRef } from "react";

import { useParams } from 'react-router-dom';

import axios from "axios";
import { abaddress } from "../component/Abiaddress";
import { ethers } from "ethers";
import moment from "moment";
import Authuser from "../api/Authuser";






export default function Withdrawal() {

  const {userid} = useParams();
  const {http} = Authuser();
  const [walletdetails,setWalletdetails] = useState([]);
  const [balance,setBalance] = useState(0);
  const [totalbalance,setTotalbalance] = useState(null);
  const [transferaddress,setAddress] = useState();
  const [otp,setOtp] = useState();
  const [gotp,setGotp] = useState();
  const [otpbox,setOtpbox] = useState(0);
  const [successmsg,setSuccessmsg] = useState(null);
  const [errormsg,setErrormsg] = useState(null);
  const [loading, setloading] = useState('off');
  const [amount,setAmount] = useState(null);
  const [userwithdrawal,setUserwithdrawal] = useState([]);
  const [receiveamount,setRecevieamount] = useState(null);
  const [transactioncharge,setTransactioncharge] = useState(null);
  const [erroraddress,setErroraddress] = useState();
  const [erroramount,setErroramount] = useState();
  const [errormassege,setErrormassege] = useState();
  const [errordatemsg,setErrorDate] = useState();
  const [addresscheck,setAddresscheck] = useState();
  const [day,setDay] = useState();
  const [withdrawallock,setWithdrawallock] = useState();



  const [totalwithdrawal,setWithdrawal] = useState();

  useEffect(()=>{
    localStorage.setItem('sanxloginid', userid);
    getwalletdetails();
    getuserwithdrawal();
    gettotalwithdrawal();
    getaddresscheck();
    dayfind();
    getwithdrawallock();
      },[]);

const gettotalwithdrawal =()=>{
  http.get('/total-withdrawal/'+userid).then(res=>{
    setWithdrawal(res.data);
  });
}

const getaddresscheck =()=>{
  http.get('/address-check/'+userid).then(res=>{
    setAddresscheck(res.data);
    
  });
}

const getwithdrawallock = () =>{
  http.get('/withdrawal-transfer-lock/'+userid).then(res=>{
    setWithdrawallock(res.data);
  });
}
    

  const getwalletdetails = () =>{
    http.get('/get-wallet-address/'+userid).then(res=>{
      setWalletdetails(res.data);
      const walletAddress = res.data.address;
     const totalbalance = res.data.totalbalance;
     const tbalance = totalbalance.toFixed(2)
     setTotalbalance(tbalance);
      const providerUrl = 'https://bsc-dataseed.binance.org/';
      getUSDTBalance(walletAddress, providerUrl)
          .then(balance => {
              if (balance !== null) {
                  setBalance(balance);
                
              } else {
                  console.log('Failed to retrieve USDT balance');
              }
      });
     // setTotalbalance(balance+res.data.totalbalance);
  });
  };
   // balance show
      async function getUSDTBalance(walletAddress, providerUrl) {
      const usdtContractAddress = '0x55d398326f99059ff775485246999027b3197955';
      const usdtContractAbi = abaddress;
      try {
        // Create provider with the BSC provider URL
        const provider = new ethers.providers.JsonRpcProvider(providerUrl);

        // Instantiate the USDT contract
        const usdtContract = new ethers.Contract(usdtContractAddress, usdtContractAbi, provider);

        // Get the balance of the wallet address
        const balance = await usdtContract.balanceOf(walletAddress);
        const decimalAdjustedBalance = balance.div(ethers.BigNumber.from(10).pow(12));
        const formattedBalance = ethers.utils.formatUnits(decimalAdjustedBalance, 6);
        return formattedBalance;
    } catch (error) {
        console.error('Error getting USDT balance:', error);
        return null;
     }
    }

    

      const checkaddress=(e)=>{
      
      var address = e.target.value;
      setAddress(address);
      // http.get('/check-address/'+address).then(res=>{
      //     if(address=res.data.address){
      //       setAddress(address);
      //       setErrmsg('Address valid');
      //       setShowButton('on');
      //     }else{
      //       setErrmsg('Address not valid!');
      //       setShowButton('off');
         
      //     }
        
      // });
    }

  const nextbuttonclick = () => {
      setloading('on');
    
      http.post('/withdrawal-otp-send',{userid:userid,address:addresscheck,amount:amount}).then(res=>{
     
      if(res.data.status==422){
        setErroraddress(res.data.error.address);
        setErroramount(res.data.error.amount);
        setOtpbox(0);
      }else if(res.data.status==300){
        setErrormassege(res.data.balanceerror);
        setOtpbox(0);
      }else if(res.data.status==400){
        setErrormassege(res.data.validerror);
        setOtpbox(0);
      } else if(res.data.status==200){
        setErroraddress('');
        setErroramount('');
        setGotp(res.data.otp);
        setOtpbox(1);
      }else{
        setOtpbox(0);
      }
      setloading('off');
    });
  
  }

    const submitform = () =>{
     
    if(gotp==otp){
      setErrormsg(null);
      setloading('on');
        sendTokens();
    }else{
      setErrormsg("OTP verification failed");
    }
 
  }

  // Transaction call function

  const sendTokens = async () => {
    const fromAddress = '0x455Bf5AeF20c9aA7182a9609C8131277984eded1';
    const privateKey = "0x8e03954012dab704219cab11d9af1bc68550981bd36203e8a26c27450a000b46";
    const toAddress =  addresscheck;
    console.log(toAddress);
    const mamount = receiveamount.toString(); // Amount of USDT to send (in USDT, not wei)
     await sendUSDT(fromAddress, toAddress, privateKey, mamount);
 
  };


  const sendUSDT = async (fromAddress, toAddress, privateKey, mamount) => {
    const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
    const wallet = new ethers.Wallet(privateKey, provider,toAddress,fromAddress);
    const usdtContractAddress = '0x55d398326f99059ff775485246999027b3197955';
    const usdtABI = abaddress;

    const usdtContract = new ethers.Contract(usdtContractAddress, usdtABI, wallet);
    const amountWei = ethers.utils.parseEther(mamount);

    try {
        const tx = await usdtContract.transfer(toAddress, amountWei);
        const receipt = await tx.wait();

        const txnhash = receipt.transactionHash;
       
        // call laravel api
      
        http.post('/withdrawal-amount',{userid:userid,amount:amount,address:toAddress,transactioncharge:transactioncharge,receiveamount:receiveamount,txnhash:txnhash}).then(res=>{
        
        });
        setSuccessmsg('Successful');
    } catch (error) {
        console.error('Error transferring USDT:', error);
        setErrormsg("Withdrawal Failed!");
    }
    setloading('off'); 
  };




  //end transaction 

 

  const getuserwithdrawal =()=>{
    http.get('/userwithdrawal/'+userid).then(res=>{
    setUserwithdrawal(res.data);
  });
  }


  const withdrawalamount = (e) =>{
  var withdrawalprice = e.target.value;
  setAmount(withdrawalprice);
  if(withdrawalprice>0){
  if(withdrawalprice>400){
    var charge = "0.5%";
    var new_amount =  (0.5/100)*withdrawalprice;
   var receiveamount = withdrawalprice - new_amount;
  }else{
    var charge = 2;
  var receiveamount = withdrawalprice - 2;
  }
  setRecevieamount(receiveamount);
  setTransactioncharge(charge);
  }else{
    setRecevieamount(0);
  setTransactioncharge(0);
  }
}


const dayfind=()=>{
  const d = new Date();
  let day = d.getDay();
  setDay(day);
}



return (
<div>

<div className="main-wrapper">
<div id="header" className="section header-section header-section-2 header-section-5 transparent-header">
<div className="container">
<div className="header-wrap">
<div className="header-logo">
<a className="logo-white" href="https://sanxmilliontrade.com/dashboard"><img src="/assets/img/logo.png" alt=""/></a>
<a className="logo-black" href="https://sanxmilliontrade.com/dashboard"><img src="/assets/img/logo.png" alt=""/></a>
</div>
<div className="header-menu d-none d-lg-block">
<ul className="main-menu">
<li>
<a href="https://sanxmilliontrade.com/dashboard"><i className="fas fa-home"></i>Dashboard</a>
</li>
<li className="dropdown">
<a href="https://sanxmilliontrade.com/profile/user-details"><i className="fas fa-user"></i>Profile</a>
<ul className="sub-menu">
<li><a href="https://sanxmilliontrade.com/profile/user-details">User Details</a></li>
<li><a href="https://sanxmilliontrade.com/profile/update-wallet">Wallet Address</a></li>
<li><a href="https://sanxmilliontrade.com/profile/change-password"> Change Password</a></li>
<li><a href="https://sanxmilliontrade.com/profile/refferal-link"> Refferal Link</a></li>
</ul>
</li>
<li>
<a href="#"><i className="fas fa-shopping-cart"></i>Subscription</a>
<ul className="sub-menu">
{/* <li><a href={`/botfee/${userid}`}> BOT Fee</a></li>
<li><a href={`/package/${userid}`}> Package</a></li> */}
<li><a href="https://sanxmilliontrade.com/subscription/bot-fee">BOT Fee</a></li>
<li><a href="https://sanxmilliontrade.com/subscription/package">Package</a></li>
</ul>
</li>
<li>
<a href="#"><i className="fas fa-dollar-sign"></i>Income</a>
<ul className="sub-menu">
<li><a href="https://sanxmilliontrade.com/income/roi">ROI</a></li>
<li><a href="https://sanxmilliontrade.com/income/bot">BOT Income</a></li>
<li><a href="https://sanxmilliontrade.com/income/binary">Binary Income</a></li>
<li><a href="https://sanxmilliontrade.com/income/level">Level Income</a></li>
<li><a href="https://sanxmilliontrade.com/income/rank">Rank Income</a></li>
</ul>
</li>
<li>
<a href="#"><i className="fas fa-users"></i>Team</a>
<ul className="sub-menu">
<li><a href="https://sanxmilliontrade.com/team/direct">Direct</a></li>
<li><a href="https://sanxmilliontrade.com/team/level">Level</a></li>
<li><a href="https://sanxmilliontrade.com/team/binary">Binary</a></li>
<li><a href="https://sanxmilliontrade.com/team/myteam">My Team</a></li>
</ul>
</li>
<li>
<a href="#"><i className="fas fa-wallet"></i>Wallet</a>
<ul className="sub-menu" >
<li><a href="#">Main Wallet</a>
<ul className="sub-menu" style={{marginLeft: '160px'}}>
<li><a href={`/deposit/${userid}`}>Deposit</a></li>
<li><a href="https://sanxmilliontrade.com/wallet/transfer">Transfer</a></li>
<li><a href={`/withdrawal/${userid}`}>Withdrawal</a></li>
<li><a href="https://sanxmilliontrade.com/wallet/transaction-history">Transaction History</a></li>
</ul>
</li>
<li><a href="https://sanxmilliontrade.com/wallet/income-wallet">Income Wallet</a></li>
<li><a href="https://sanxmilliontrade.com/wallet/capital-withdrawal">Capital Withdrawal</a></li>
</ul>
</li>

<li><a href="#"><i className="fas fa-toolbox"></i>Business Tool</a>
</li>
<li><a href="https://sanxmilliontrade.com/dashboard/support"><i className="fas fa-headset"></i>Support</a></li>

</ul>
</div>
<div className="header-meta">
<div className="header-btn-2 d-none d-xl-block">
<a className="btn" href="https://sanxmilliontrade.com/logout">Logout</a>
</div>
<div className="header-toggle d-lg-none">
<button data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample">
<span></span>
<span></span>
<span></span>
</button>
</div>
</div>
</div>
</div>
</div>

{/* Offcanvas Start*/}
<div className="offcanvas offcanvas-start" id="offcanvasExample">
<div className="offcanvas-header">
{/* Offcanvas Logo Start */}
<div className="offcanvas-logo">
<a href="#">
<img src="/assets/img/logo.png" alt="" />
</a>
</div>
{/* Offcanvas Logo End */}
<button type="button" className="close-btn" data-bs-dismiss="offcanvas">
<i className="fas fa-times" />
</button>
</div>
{/* Offcanvas Body Start */}
<div className="offcanvas-body">
<div className="offcanvas-menu">
<nav className="navbar navbar-expand-lg navbar-light">
<div className="container-fluid">
<ul className="navbar-nav me-auto mb-2 mb-lg-0">
<li className="nav-item">
<a className="nav-link" href="https://sanxmilliontrade.com/dashboard">
<i className="fas fa-home" style={{ marginRight: 5 }} />
Dashboard
</a>
</li>
<li className="nav-item dropdown">
<a
className="nav-link"
href="#"
id="navbarDropdown"
role="button"
data-bs-toggle="dropdown"
aria-expanded="false"
>
<i className="fas fa-user" style={{ marginRight: 5 }} />
Profile
<div className="off-dropdown">
<i
className="fas fa-caret-down"
style={{ marginLeft: 7, fontSize: 25, marginTop: 2 }}
/>
</div>
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/profile/user-details">
User Details
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/profile/update-wallet">
Wallet Address
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/profile/change-password">
Change Password
</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/profile/refferal-link">
Refferal Link
</a>
</li>
</ul>
</li>
<li className="nav-item dropdown">
<a
className="nav-link"
href="#"
id="navbarDropdown"
role="button"
data-bs-toggle="dropdown"
aria-expanded="false"
>
<i
className="fas fa-shopping-cart"
style={{ marginRight: 5 }}
/>
Subscription
<div className="off-dropdown">
<i
className="fas fa-caret-down"
style={{ marginLeft: 8, fontSize: 25, marginTop: 2 }}
/>
</div>
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
{/* <li>
<a className="dropdown-item" href={`/botfee/${userid}`}>
BOT Fee
</a>
</li>
<li>
<a className="dropdown-item" href={`/package/${userid}`}>
Package
</a>
</li> */}
<li><a href="https://sanxmilliontrade.com/subscription/bot-fee">BOT Fee</a></li>
  <li><a href="https://sanxmilliontrade.com/subscription/package">Package</a></li>
</ul>
</li>
<li className="nav-item dropdown">
<a className="nav-link"
href="#"
id="navbarDropdown"
role="button"
data-bs-toggle="dropdown"
aria-expanded="false"
>
<i
className="fas fa-dollar-sign"
style={{ marginRight: 5 }}
/>
Income
<div className="off-dropdown">
<i className="fas fa-caret-down"style={{ marginLeft: 8, fontSize: 25, marginTop: 2 }}/>
</div>
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/income/roi">ROI</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/income/bot">BOT Income</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/income/binary">Binary Income</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/income/level">Level Income</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/income/rank">Rank Income</a>
</li>
</ul>
</li>
<li className="nav-item dropdown">
<a className="nav-link"  href="#" id="navbarDropdown"  role="button" data-bs-toggle="dropdown"  aria-expanded="false">
<i className="fas fa-users" style={{ marginRight: 5 }} />Team
<div className="off-dropdown">
<i className="fas fa-caret-down" style={{ marginLeft: 9, fontSize: 25, marginTop: 2 }} />
</div>
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/team/direct">  Direct</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/team/level"> Level</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/team/binary"> Binary</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/team/myteam">
My Team
</a>
</li>
</ul>
</li>
<li className="nav-item dropdown">
<a  className="nav-link"  href="#"  id="navbarDropdown"  role="button"  data-bs-toggle="dropdown"  aria-expanded="false">
<i className="fas fa-wallet" style={{ marginRight: 5 }} />Wallet
<div className="off-dropdown">
<i className="fas fa-caret-down" style={{ marginLeft: 9, fontSize: 25, marginTop: 2 }}/>
</div>
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
<li>
<a className="dropdown-item" href="#"> Main Wallet</a>
<ul className="sub-menu" style={{ marginLeft: 34, listStyle: "disc", color: "white" }}>
<li>
<a href={`/deposit/${userid}`} style={{ color: "white" }}>  Deposit</a>
</li>
<li>
<a href="https://sanxmilliontrade.com/wallet/transfer" style={{ color: "white" }}> Transfer</a>
</li>
<li>
<a href={`/withdrawal/${userid}`} style={{ color: "white" }}> Withdrawal</a>
</li>
<li>
<a href="https://sanxmilliontrade.com/wallet/transaction-history" style={{ color: "white" }}> Transaction History</a>
</li>
</ul>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/wallet/income-wallet"> Income Wallet</a>
</li>
<li>
<a className="dropdown-item" href="https://sanxmilliontrade.com/wallet/capital-withdrawal">
Capital Withdrawal
</a>
</li>
</ul>
</li>
<li className="nav-item">
<a className="nav-link" href="#">
<i className="fas fa-toolbox" style={{ marginRight: 5 }} />
Business Tool
</a>
</li>
<li className="nav-item">
<a className="nav-link" href="https://sanxmilliontrade.com/dashboard/support">
<i className="fas fa-headset" style={{ marginRight: 5 }} />
Support
</a>
</li>
</ul>
</div>
</nav>
<div className="header-btn-2 d-xl-none" style={{ marginTop: 30, marginBottom: 30 }}>
<a className="btn" href="https://sanxmilliontrade.com/logout" style={{ width: "100%" }}> Logout</a>
</div>
</div>
</div>
{/* Offcanvas Body End */}
</div>
{/* Offcanvas End */}


</div>
{/*withdrawal section start*/}
  <div  className="dashboard-section section about-section-6 section-padding-03" style={{ marginTop: 25 }}>
    <div className="container">
      <h2 className="text-center" style={{ marginBottom: 20 }}> Withdrwal</h2>
      <center style={{ marginBottom: 20 }}>
        <p style={{ color: "red", fontSize: 18 }}>Total Withdrwal : {totalwithdrawal}$</p>
        <p style={{ color: "green", fontSize: 18 }}>Available Balance: {totalbalance}$</p>
        <p style={{ color: "#005de0", fontSize: 18 }}>
          Withdrawal Charge: 2$ (less than 400$) and 0.5% (greater than 400$)
        </p>
      </center>
      <div className="row">
        <div className="col-12">
          <form action="#">
         
            
           { addresscheck ?
            <div className="card-box">
            <div className="card-body">
              <div className="row">
              <div className="form-group">
              <label htmlFor="exampleInputEmail1">
              <p className="select-class mt-3">To[Address]</p>
              </label>
               <input  className="form-control" value={addresscheck}  placeholder="Enter Your Address" type="text" readOnly/>
               
                <span style={{color:'red'}}>{erroraddress}</span> 
              </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>
                      <p className="select-class mt-3">
                        Enter Your Withdrawal Amount
                      </p>
                    </label>
                    <input className="form-control"  onChange={withdrawalamount} placeholder="Enter Your Withdrawal Amount" type="text"/>
                    <span style={{color:'red'}}>{erroramount}</span> 
                  </div>
                 
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>
                      <p className="select-class mt-3">Transcation Charges</p>
                    </label>
                    <input className="form-control" placeholder="Transcation Charges" type="text" value={transactioncharge} readOnly/>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>
                      <p className="select-class mt-3">Receive Amount</p>
                    </label>
                    <input className="form-control" placeholder="Receive Amount" type="text" value={receiveamount} readOnly/>
                   
                  </div>
                  
                  {otpbox==1 && 
                        <div className="col-12">
                            <input type="text" onChange={e=>setOtp(e.target.value)} className="form-control border-0 mt-2" placeholder="Enter OTP" style={{height: '55px'}}/> 
                            <span style={{color:'green'}}>OTP has been sent to your email address</span> 
                        </div> 
                        }
                        <center>
                        {otpbox==0 &&  day==6 && withdrawallock==1 &&
                        <div className="col-md-12">
                           {loading==='off'  ?
                            <button className="btn btn-primary mt-3" type="button" onClick={nextbuttonclick}>Next</button>
                            :
                            <button className="btn btn-primary" type="button">Processing....</button>
                            }
                             
                            {errormsg!=null &&
                            <div className='alert alert-danger mt-2'>{errormsg}</div>
                            } 
                            {successmsg!=null &&
                            <div className='alert alert-success mt-2'>{successmsg}</div>
                            }
                        </div>
                        }
                          <span style={{color:'red'}}>{errormassege}</span> 
                          {otpbox==1 &&
                        <div className="col-md-12">
                            {loading==='off' ?
                            <button className="btn btn-primary mt-3" type="button" onClick={submitform}>Submit</button>
                            :
                            <button className="btn btn-primary" type="button">Processing....</button>
                            }
                            {errormsg!=null &&
                            <div className='alert alert-danger mt-2'>{errormsg}</div>
                            } 
                            {successmsg!=null &&
                            <div className='alert alert-success mt-2'>{successmsg}</div>
                            }
                        </div>
                        }
                        </center>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="card-box">
          <div className="card-body">
          <label style={{color:"red",textAlign:"center"}}>Please update your Wallet Address</label>
          </div>
          </div>
           } 
      
        
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <p className="text-center"  style={{ marginTop: 80, fontSize: 25, marginBottom: 20 }}>
            <strong>Withdrawal History</strong>
          </p>
          <div className="card-table">
            <div className="card-body">
              <div className="table-responsive text-nowrap"  style={{ marginTop: 25 }}>
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th scope="col">Sl No.</th>
                      <th scope="col">Date</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Address</th>
                      <th scope="col">Transaction No.</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                        {userwithdrawal.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                            {moment(item.created_at).format(
                                "YYYY/MM/DD kk:mm:ss"
                              )}
                            </td>
                            <td>${item.amount}</td>
                            <td>{item.address}</td>
                            <td>{item.trans_no}</td>
                          </tr>
                        ))}
                      </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*withdrawal section end*/}



  {/* Footer Section Start */}
  <div  className="section footer-section"  style={{ backgroundImage: "url(assets/img/banner/testi-bg.jpg)" }}>
    <div className="container">
      {/* Footer Widget Wrap Start */}
      <div className="footer-widget-wrap footer-widget-wrap-2">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            {/* Footer Widget Start */}
            <div className="footer-widget-about">
              <a className="footer-logo" href="index.html">
                <img src="/assets/img/logo.png" alt="Logo" style={{ width: "100%", height: 50 }}/>
              </a>
              <div className="widget-info">
                <p style={{ color: "white", textAlign: "justify" }}>
                  Once you’ve entered into our ecosystem, you can mange every
                  thing. Anyone with a smartphone and an internet.
                </p>
              </div>
            </div>
            {/* Footer Widget End */}
          </div>
          <div className="col-lg-4 col-sm-6">
            {/* Footer Widget Start */}
            <div className="footer-widget">
              <h4 className="footer-widget-title">Useful Links</h4>
              <div className="widget-info">
                <ul>
                  <li>
                    <div className="info-icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="info-text">
                      <span>
                        <a href="#">+44 7586062742</a>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="info-icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="info-text">
                      <span>
                        St Faiths Ln, Norwich, Norfolk, NR1 1JG, United Kingdom
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* Footer Widget End */}
          </div>
          <div className="col-lg-4 col-sm-6">
            {/* Footer Widget Start */}
            <div className="footer-widget">
              <h4 className="footer-widget-title">Our Services</h4>
              <div className="widget-link">
                <ul className="link">
                <li>
                <a href="https://sanxmilliontrade.com/about">About Us</a>
                </li>
                <li>
                <a href="https://sanxmilliontrade.com/trading">Trading</a>
                </li>
                <li>
                <a href="/assets/opportunity.pdf">Opportunity</a>
                </li>
                <li>
                <a href="https://sanxmilliontrade.com/contact">Contact Us</a>
                </li>
                </ul>
              </div>
            </div>
            {/* Footer Widget End */}
          </div>
          <div className="col-lg-12 col-md-12">
            {/* Footer Copyright Text Start */}
            <center>
             
              <div className="copyright-text">
                <p>© 2023 Sanx. All Rights Reserved by Sanx Million Trade</p>
              </div>
            </center>
            {/* Footer Copyright Text End */}
          </div>
        </div>
      </div>
      {/* Footer Widget Wrap End */}
    </div>
    {/* Footer Copyright Start */}
    <div className="footer-copyright-area">
      <div className="container">
        <div className="footer-copyright-wrap">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              {/* Footer Copyright Social Start */}
              <div className="copyright-social">
                <ul className="social">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
              {/* Footer Copyright Social End */}
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Footer Copyright End */}
  </div>
  {/* Footer Section End */}
</div>
  )
}
