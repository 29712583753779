import React, { useEffect, useState } from "react";
import axios from 'axios';

export default function Authuser() {
    const bUrl = "https://adminpanel.sanxmilliontrade.com/"; 
    const murl = "https://sanxmilliontrade.com/";
    const [token,setToken] = useState();

    const getToken = () => {
        const tokenString = localStorage.getItem('sanxloginid');
        return tokenString;
    }
    const saveToken = (token) => {
        localStorage.setItem('sanxloginid', JSON.stringify(token));
        setToken(token);
    }
    const http = axios.create({
        baseURL: "https://adminpanel.sanxmilliontrade.com/api",
        headers: {
            "content-type":"application/json"
        }
    });
    

    return {
        getToken,
        setToken:saveToken,
        http,
        bUrl,
        murl,
    }
}
